import React, { useEffect, useRef, useState } from "react";
import MapContainer from "./Map";
import Contact from "./Contact";
import Navbar from "../Navbar/Navbar";
import Footer from "./Footer";


function ContactAll() {
    const [showContactForm, setShowContactForm] = useState(false);

    // Toggle contact form visibility
    const handleContactClick = () => {
        setShowContactForm(true); // Show form, hide button
    };

    // Close form when clicking outside
    const handleCloseForm = () => {
        setShowContactForm(false); // Hide form, show button
    };
    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollContent = scrollRef.current;
        const items = scrollContent.querySelectorAll('.item');
        const totalWidth = Array.from(items).reduce((acc, item) => acc + item.offsetWidth + parseFloat(getComputedStyle(item).marginRight), 0);
        
        // Duplicate the items for seamless scrolling
        scrollContent.innerHTML += scrollContent.innerHTML;

        // Set the animation duration based on total width
        const duration = totalWidth / 50; // Adjust 50 for speed
        scrollContent.style.animationDuration = `${duration}s`;
    }, []);
    console.log(showContactForm)

    return (
        <div >
            <Navbar />
            <MapContainer
                showContactForm={showContactForm}
                onContactClick={handleContactClick}
                onCloseForm={handleCloseForm}
            />
            <div className="scroll-container">
                <div className="scroll-content" ref={scrollRef}>
                    <div className="item">
                        <div className="itemheadsc">
                            <div className="dot"></div>
                            <p className="headingscollerb">PLASTIC</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="itemheadsc">
                            <div className="dot"></div>
                            <p className="headingscollerb">CHEMICAL</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="itemheadsc">
                            <div className="dot"></div>
                            <p className="headingscollerb">ADDITIVES</p>
                        </div>
                    </div>
                    <div className="item">
                        <div className="itemheadsc">
                            <div className="dot"></div>
                            <p className="headingscollerb">MASTER BATCHES</p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default ContactAll;
