import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";



function Chemical() {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const navigate = useNavigate()
    // Define products with specific border-radius
    const products = [
        { id: 'pet', name: 'Polyether Polyol', borderRadius: '80px' },
        { id: 'pp', name: 'Polyester Polyol', borderRadius: '20px' },
        { id: 'pvc', name: 'N N Dimethylcyclohexylamine (DMCHA)', borderRadius: '80px' },
        { id: 'pbt', name: 'Diphenylmethane Diisocyanate (MDI)', borderRadius: '20px' },
        { id: 'abs', name: 'Tetrahydrofuran (THF)', borderRadius: '80px' },
        { id: 'ldpe', name: 'Toulene', borderRadius: '20px' },
        
        { id: 'pmma', name: 'Off-Spec PTA', borderRadius: '80px' },
        { id: 'san', name: 'Purified Terephthalic Acid (PTA)', borderRadius: '20px' },
        { id: 'pom', name: 'Bisphenol A', borderRadius: '80px' },
        { id: 'san', name: 'Phenol', borderRadius: '20px' },
        { id: 'pom', name: 'C20+', borderRadius: '80px' },
        { id: 'san', name: 'Acetone', borderRadius: '20px' },
    ];

    

    return (
        <>
            <Navbar />
            <div className="polysinglepage">
                <div className="polycsssingle">
                    <div className="goback" onClick={()=>navigate(-1)}>
                        <FaArrowLeft width={"36px"} height={"36px"} />
                        <p className="backbutton">Back</p>
                    </div>
                    <div className="polymersheadingmain">
                        <p className="headingpolyinptag">CHEMICALS</p>
                    </div>
                    <p className="productsmaincontpoly">PRODUCTS</p>
                    <div className="products-container">

                        <div className="products-list">
                            {products.map(product => (
                                <div
                                    key={product.id}
                                    className={"product-item" }
                                    onClick={() => setSelectedProduct(product.id)}
                                    style={{ borderRadius: product.borderRadius }}
                                >
                                    {product.name}
                                </div>
                            ))}
                        </div>

                        
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Chemical;