// import sea from "../Images/Home water ship.svg"
import video from "../Images/styroplast home.mp4"
// import water from "../Images/HOME water.svg"
import watership from "../Images/img/web/1.png"
// import video from "../Images/img/mobile/syrovideo.gif"
import Navbar from "../Navbar/Navbar";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import product1 from "../Images/product/FIND THE PRODUCT 1.svg"
// import product2 from "../Images/product/FIND THE PRODUCT 2.svg"
// import product3 from "../Images/product/FIND THE PRODUCT 3.svg"
// import product4 from "../Images/product/FIND THE PRODUCT 4.svg"
// import product5 from "../Images/product/FIND THE PRODUCT 5.svg"
// import product6 from "../Images/product/FIND THE PRODUCT 6.svg"
// import product7 from "../Images/product/FIND THE PRODUCT 7.svg"
// import product8 from "../Images/product/FIND THE PRODUCT 8.svg"
// import product9 from "../Images/product/FIND THE PRODUCT 9.svg"
import productmon1 from "../Images/img/mobile/2.jpeg"
import productmon2 from "../Images/img/mobile/3.jpeg"
import productmon3 from "../Images/img/mobile/4.jpeg"
import productmon4 from "../Images/img/mobile/5.jpeg"
import productmon5 from "../Images/img/mobile/8.jpeg"
import productmon6 from "../Images/img/mobile/9.jpeg"
import productmon7 from "../Images/img/mobile/10.jpeg"
import productmon8 from "../Images/img/mobile/11.jpeg"
import productmon9 from "../Images/img/mobile/12.jpeg"
import { useEffect, useRef, useState } from "react";
import chemical from "../Images/img/mobile/13.png"
import plastic from "../Images/img/mobile/15.png"
import packaging from '../Images/img/mobile/17.png'
import textile from '../Images/img/mobile/18.png'
import steel from '../Images/img/mobile/16.png'
import polyurethane from '../Images/img/mobile/14.png'
import defence from '../Images/img/mobile/19.png'
import Footer from "./Footer";
// import watership from "../Images/1.svg"

const industries = [
    {
        name: "PLASTIC",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: plastic, // Path to the Plastic image
        // image: 'https://via.placeholder.com/1500x800.png',
    },
    {
        name: "CHEMICAL",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: chemical,
    },
    {
        name: "Packaging",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: packaging,
    },
    {
        name: "Textile",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: textile,
    },
    {
        name: "Steel",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: steel,
    },
    {
        name: "Polyurethane",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: polyurethane,
    },
    {
        name: "Defence",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: defence,
    },


];

const industries1 = [
    {
        name: "PLASTIC",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: plastic, // Path to the Plastic image
        // image: 'https://via.placeholder.com/1500x800.png',
    },
    {
        name: "CHEMICAL",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: chemical,
    },
    {
        name: "Packaging",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: packaging,
    },
    {
        name: "Textile",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: textile,
    },
    {
        name: "Steel",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: steel,
    },
    {
        name: "Polyurethane",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: polyurethane,
    },
    {
        name: "Defence",
        description: "We prioritize sustainable sourcing and production practices to support eco-conscious clients in reducing their environmental footprint. Through innovation and reliability, we are a trusted partner in shaping a responsible future in the plastics sector and are in constant efforts to be better everyday.",
        image: defence,
    },

];
function Home() {

    const quotes = [
        {
            text: "Para ter sucesso, você deve aceitar todos os desafios que aparecem no seu caminho. Você não pode simplesmente aceitar aqueles que você gosta.",
            author: "Mike Gafka",
        },
        {
            text: "सफलता यह नही है कि आप कि तनी ऊँ चाई पर चढ़ेहै, ब लि यह है कि आप दुिनया मेकि तना सकारातक बदलाव लातेहै।",
            author: "रॉय टी. बेनेट",
        },
        {
            text: "Olağanüstü fikirlerden eksiklik yok, eksik olan onları uygulama isteği",
            author: "Seth Godin",
        },
        {
            text: "Juega según las reglas, pero sé feroz",
            author: "Phil Knight",
        },
    ];

    const images = [
        productmon1,
        productmon2,
        productmon3,
        productmon4,
        productmon5,
        productmon6,
        productmon7,
        productmon8,
        productmon9
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);
    const [selectedIndustry, setSelectedIndustry] = useState(industries[0]);

    const handleIndustryChange = (industry) => {
        setSelectedIndustry(industry);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setAnimate(true);
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % quotes.length);
                setAnimate(false);
            }, 1000); // Duration of the slide-up animation
        }, 5000); // Change quote every 5 seconds
        return () => clearInterval(interval);
    }, [quotes.length]);
    const sliderSettings = {
        dots: true,           // Show dots for navigation
        infinite: false,      // Disable infinite scroll
        speed: 500,           // Transition speed
        slidesToShow: 1,      // Show one slide at a time
        slidesToScroll: 1,    // Scroll one slide at a time
        swipeToSlide: true,   // Allow swipe to slide with thumb
        responsive: [
            {
                breakpoint: 768, // Apply this setting for screens smaller than 768px
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const trackRef = useRef(null);
    const [currentPosition, setCurrentPosition] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(true); // Track transition

    const speed = 1; // Speed of sliding
    const totalImages = images.length;

    const slide = () => {
        setCurrentPosition((prevPosition) => {
            const trackWidth = trackRef.current.scrollWidth / 2; // Half of total track width

            // If we've reached the end of the original + duplicated images
            if (Math.abs(prevPosition) >= trackWidth) {
                setIsTransitioning(false); // Disable transition
                return 0; // Reset position without transition
            }

            return prevPosition - speed;
        });
    };

    useEffect(() => {
        const animation = requestAnimationFrame(() => slide());

        return () => {
            cancelAnimationFrame(animation);
        };
    }, [currentPosition]);

    // Re-enable transition once position resets
    useEffect(() => {
        if (!isTransitioning) {
            setTimeout(() => {
                setIsTransitioning(true);
            }, 50); // Small delay to avoid jumpy effect
        }
    }, [isTransitioning]);

    return (
        <>
            <Navbar />
            <div className="containermob">
                <div className="background-watermob">
                    <img src={watership} alt="Water" />
                </div>
                <div className="video-wrappermob">
                    <video className="centered-videomob" autoPlay muted loop>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div className="text-overlaymob">
                        <h1>STYROPLAST</h1>
                        <h2>TRADING PVT LTD</h2>
                    </div>
                </div>
            </div>
            <div className="container">

                {/* <img src={sea} alt="Ship" className="ship-image" /> */}



                <div className="background">

                    <div className="background-water">
                        <img src={watership} alt="Water" />
                    </div>
                </div>



                <div className="video-wrapper">
                    <video className="centered-video" autoPlay muted loop>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                    <div className="text-overlay">
                        <h1>STYROPLAST</h1>
                        <h2>TRADING PVT LTD</h2>
                    </div>
                </div>
            </div>
            <div className="textaboutus">
                <div className="aboutusclass">
                    <div className="aboutusdivcenter">
                        <h2 className="aboutustext">
                            ABOUT US
                        </h2>
                    </div>
                    <div className="aboutustextsmallptag">
                        <p className="aboutussmallpara">
                            Styroplast Trading Private Limited was established in the year 2005. Since then, Styroplast has grown significantly in the plastic trading industry, driven by increased demand for raw materials and recycled products, enhancing consumer convenience standards. Initially focused on sourcing high-quality materials and managing logistics, Styroplast expanded into market knowledge and management which has been serving diverse sectors, including plastics, chemicals, steel, and specialty products worldwide. While starting with PET polymer trading, the company has diversified into various polymers and chemicals to meet broad customer demands. Styroplast's evolution positions it as a global leader in supplying the plastic manufacturing industry.
                        </p>
                    </div>
                </div>
            </div>

            <div className="product-slider-container">
                <div className="banner">

                    <div className="arch-section">
                        <div className="arch-shape"></div>
                    </div>


                    <div className="getlittilemarginexplore">
                        <div className="content">
                            <p className="explore">EXPLORE</p>
                            <h1 className="main-text">FIND THE RIGHT PRODUCT</h1>

                        </div>

                        <button className="view-all">
                            View All<i class="fa-solid fa-arrow-right"></i>
                        </button>
                        <div className="viewalldiv">
                            <button className="view-allmob">
                                View All
                            </button>
                            <i class="fa-solid fa-arrow-right fa-2xs"></i>
                        </div>
                    </div>

                </div>




                <div className="slider-wrapper">
                    <div
                        className="slider-track"
                        ref={trackRef}
                        style={{
                            transform: `translateX(${currentPosition}px)`,
                            transition: isTransitioning ? 'transform 0.5s linear' : 'none',
                        }}
                    >
                        {/* Duplicate the images for seamless loop */}
                        {images.concat(images).map((image, index) => (
                            <img key={index} src={image} alt={`Slide ${index}`} className="slider-img" />
                        ))}
                    </div>
                </div>

                <div className="slider-wrappermob">
                    <div className="slider-trackmob">
                        <img src={productmon1} alt="Product 1" className="slider-imgmob" />
                        <img src={productmon2} alt="Product 2" className="slider-imgmob" />
                        <img src={productmon3} alt="Product 3" className="slider-imgmob" />
                        <img src={productmon4} alt="Product 4" className="slider-imgmob" />
                        <img src={productmon9} alt="Product 5" className="slider-imgmob" />
                        <img src={productmon5} alt="Product 6" className="slider-imgmob" />
                        <img src={productmon6} alt="Product 7" className="slider-imgmob" />
                        <img src={productmon7} alt="Product 8" className="slider-imgmob" />
                        <img src={productmon8} alt="Product 9" className="slider-imgmob" />

                        <img src={productmon2} alt="Product 2" className="slider-imgmob" />
                        <img src={productmon3} alt="Product 3" className="slider-imgmpb" />
                        <img src={productmon4} alt="Product 4" className="slider-imgmob" />
                        <img src={productmon9} alt="Product 5" className="slider-imgmob" />
                        <img src={productmon5} alt="Product 6" className="slider-imgmob" />
                        <img src={productmon6} alt="Product 7" className="slider-imgmob" />
                        <img src={productmon7} alt="Product 8" className="slider-imgmob" />
                        <img src={productmon8} alt="Product 9" className="slider-imgmob" />

                    </div>
                </div>
            </div>


            <div className="industry-section" >

                <div className="indtextsection">
                    <p className="industry-textser">INDUSTRIES WE SERVE</p>
                </div>
                <div className="divbestright">
                    <div className="divtext">
                        <div className="savetext">
                            <h1 className="industrytext">{selectedIndustry.name}</h1>
                        </div>
                        <div className="savepara">
                            <p className="industrypara">{selectedIndustry.description}</p>
                        </div>
                    </div>
                </div>

                <div className={selectedIndustry.name === 'Packaging' ? "pertpacking" : (selectedIndustry.name === "PLASTIC") ? 'pertimagechemical' : selectedIndustry.name === "Steel" ? "pertimagesteel"
                    : selectedIndustry.name === "Polyurethane" ? 'pertimagepoly'
                        : selectedIndustry.name === "Defence" ? 'pertimagedef'
                            : selectedIndustry.name === "CHEMICAL" ? 'pertimagechem'
                                : "pertimage"}>

                    <img
                        className={
                            selectedIndustry.name === 'Packaging'
                                ? 'imgchangingindustrypackaging'
                                : selectedIndustry.name === 'CHEMICAL'
                                    ? 'imgchangingindustrychem'
                                    : selectedIndustry.name === 'PLASTIC'
                                        ? 'imgchangingindustrychemical'
                                        : selectedIndustry.name === "Steel" ?
                                            'imgchangingindustrySteel'
                                            : selectedIndustry.name === "Polyurethane" ?
                                                'imgchangingindustryPoly'
                                                : selectedIndustry.name === "Defence" ?
                                                    'imgchangingindustryDefe'
                                                    : 'imgchangingindustry'
                        }
                        src={selectedIndustry.image}
                        alt={selectedIndustry.name}
                    />


                </div>
                <div className="divbestbutton">
                    <div className="listall">
                        {
                            industries.map((industry) => (
                                <p
                                    key={industry.name}
                                    className={`alllisttext ${selectedIndustry.name === industry.name ? "selected-industry" : ""}`}
                                    onClick={() => handleIndustryChange(industry)}
                                >
                                    {industry.name}
                                </p>
                            ))
                        }
                    </div>

                </div>
                <div className="onlycolorbg">

                </div>



            </div>
            {/* <div className="industry-sectionmob" >
               
               <div className="indtextsectionmob">
                   <p className="industry-textsermob">INDUSTRIES WE SERVE</p>
               </div>
               <div className="divbestrightmob">
                   <div className="divtextmob">
                       <div className="savetextmob">
                           <h1 className="industrytextmob">{selectedIndustry.name}</h1>
                       </div>
                       <div className="saveparamob">
                           <p className="industryparamob">{selectedIndustry.description}</p>
                       </div>
                   </div>
               </div>

               <div className={selectedIndustry.name === 'Packaging' ? "pertpackingmob" : "pertimagemob"}>

                   <img
                       className={
                           selectedIndustry.name === 'Packaging'
                               ? 'imgchangingindustrypackagingmob'
                               : 'imgchangingindustrymob'
                       }
                       src={selectedIndustry.image}
                       alt={selectedIndustry.name}
                   />


               </div>
              
               <div className="onlycolorbgmob">

               </div>



           </div> */}
            <div className="industry-sectionmob1">
                <Slider {...sliderSettings}>
                    {industries.map((industry) => (
                        <div key={industry.name} className="industry-slide">
                            <div className="indtextsectionmob1">
                                <p className="industry-textsermob1">INDUSTRIES WE SERVE</p>
                            </div>
                            <div className="divbestrightmob1">
                                <div className="divtextmob1">
                                    <div className="savetextmob1">
                                        <h1 className="industrytextmob1">{industry.name}</h1>
                                    </div>
                                    <div className="saveparamob1">
                                        <p className="industryparamob1">{industry.description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="pertimagemob1">
                                <img
                                    className="imgchangingindustrypackagingmob1"
                                    src={industry.image}
                                    alt={industry.name}
                                />
                            </div>
                            <div className="onlycolorbgmob1">

                            </div>
                        </div>
                    ))}
                </Slider>

            </div>

            <div className="quote-slider">
                <div className="quotegetslide">
                    <div className={`quote-text ${animate ? "slide-up" : ""}`}>
                        <p className="quotepara">"{quotes[currentIndex].text}"</p>
                        <span className="author">– {quotes[currentIndex].author}</span>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home;