import Navbar from "../Navbar/Navbar";
import Footer from "./Footer";
import mygif from "../Images/img/web/what.gif"
import { useEffect, useRef, useState } from "react";
import marketing from "../Images/img/web/marketing.jpeg"
import logistic from "../Images/img/web/logistics.jpeg"
import inputs from "../Images/img/web/inputsourcing.jpeg"
import marketsed from "../Images/img/web/marketseeding.jpeg"
import map from "../Images/MAP.svg"
import Slider from "react-slick";

function WhatWeDO() {
    const [activeTab, setActiveTab] = useState('marketing');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const getContainerBackgroundColor = () => {
        switch (activeTab) {
            case 'input-sourcing':
                return 'rgb(32, 30, 35)'; // Change to your preferred color for Input Sourcing
            case 'logistics':
                return 'rgb(32, 30, 35)'; // Change to your preferred color for Logistics
            case 'market-seeding':
                return 'rgb(32, 30, 35)'
            default:
                return '#000'; // Default color (black) for other tabs
        }
    };

    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollContent = scrollRef.current;
        const items = scrollContent.querySelectorAll('.item');
        const totalWidth = Array.from(items).reduce((acc, item) => acc + item.offsetWidth + parseFloat(getComputedStyle(item).marginRight), 0);

        // Duplicate the items for seamless scrolling
        scrollContent.innerHTML += scrollContent.innerHTML;

        // Set the animation duration based on total width
        const duration = totalWidth / 25; // Adjust 50 for speed
        scrollContent.style.animationDuration = `${duration}s`;
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,  // You can change this depending on screen size
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2, // Change the number of visible tabs on mobile
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1, // Show only one tab at a time on smaller screens
                },
            },
        ],
    };
    return (
        <>
            <Navbar />
            <div className="maindivwhat">
                <div className="maindivpeoplewhat">
                    <p className="whattodotext">What to do</p>
                    <div className="whattodivptag">


                        <p className="whattotextpara">
                            We bring extensive expertise in sourcing, distributing, and managing high-quality materials. With a deep understanding of market dynamics and a commitment to sustainability, we provide tailored solutions to meet the diverse needs of our clients. Our dedication to innovation and excellence ensures that we consistently deliver value and reliability.
                        </p>
                    </div>
                    <div className="suppliergif">
                        <p className="supplierwhat">SUPPLIER</p>
                        <img src={mygif} alt="" className="gifimagewhat" />
                        <p className="userendsupp">END&nbsp;USER</p>
                    </div>
                </div>
                <div className="suppliergifmob">
                    <p className="supplierwhatmob">SUPPLIER</p>
                    <img src={mygif} alt="" className="gifimagewhatmob" />
                    <p className="userendsuppmob">END&nbsp;USER</p>
                </div>
            </div>
            <div className="tab-container" style={{ backgroundColor: getContainerBackgroundColor() }}>
                <div className="tab-insidecontain">
                    {/* Tab Navigation */}
                    <div className="tab-navigation">
                        <p
                            className={`tab-item ${activeTab === 'marketing' ? 'active' : ''}`}
                            onClick={() => handleTabClick('marketing')}
                        >
                            MARKETING
                        </p>
                        <p
                            className={`tab-item ${activeTab === 'input-sourcing' ? 'active' : ''}`}
                            onClick={() => handleTabClick('input-sourcing')}
                        >
                            INPUT SOURCING
                        </p>
                        <p
                            className={`tab-item ${activeTab === 'logistics' ? 'active' : ''}`}
                            onClick={() => handleTabClick('logistics')}
                        >
                            LOGISTICS
                        </p>
                        <p
                            className={`tab-item ${activeTab === 'market-seeding' ? 'active' : ''}`}
                            onClick={() => handleTabClick('market-seeding')}
                        >
                            MARKET SEEDING
                        </p>
                    </div>

                    {/* Tab Content */}
                    <div className="tab-content marketing-content">
                        {activeTab === 'marketing' && (
                            <div className="content">
                                <div className="chnageevrytime">
                                    <h1 className="maindivheading">MARKETING</h1>
                                    <p className="paramainhead2">
                                        Our trading company excels in connecting manufacturers with top-tier materials through a global network. We prioritize customer satisfaction by offering competitive pricing, timely delivery, and personalized service. Leveraging our industry expertise and innovative solutions, we help businesses thrive in an ever-evolving market.
                                    </p>
                                </div>
                                <div>
                                    <img src={marketing} alt="" className="div2market" />
                                </div>

                            </div>
                        )}
                        {activeTab === 'input-sourcing' && (
                            <div className="content">
                                <div className="chnageevrytime">
                                    <h1 className="maindivheading">INPUT SOURCING</h1>
                                    <p className="paramainhead2">
                                        We pride ourselves on sourcing the highest quality products from reliable and reputable suppliers worldwide. Our extensive network allows us to provide a diverse range of materials, ensuring we meet the specific needs of our clients. We are committed to stringent quality control and sustainability practices, delivering excellence in every shipment.
                                    </p>
                                </div>
                                <div>
                                    <img src={inputs} alt="" className="div2market" />
                                </div>
                            </div>
                        )}
                        {activeTab === 'logistics' && (
                            <div className="content">
                                <div className="chnageevrytime">
                                    <h1 className="maindivheading">LOGISTICS</h1>
                                    <p className="paramainhead2">
                                        Efficient logistics are key to our success. We ensure timely and secure delivery of materials through our robust network of transportation partners. Our advanced tracking systems and meticulous planning guarantee that every shipment reaches its destination safely and on schedule, meeting the highest standards of reliability and customer satisfaction.
                                    </p>
                                </div>
                                <div>
                                    <img src={logistic} alt="" className="div2market" />
                                </div>
                            </div>
                        )}
                        {activeTab === 'market-seeding' && (
                            <div className="content ">
                                <div className="chnageevrytime">
                                    <h1 className="maindivheading">MARKET SEEDING</h1>
                                    <p className="paramainhead2">
                                        Market seeding is a cornerstone of our strategy. We actively introduce innovative materials to new and emerging markets, helping to drive industry growth and development. By partnering with forward-thinking manufacturers and leveraging our market insights, we ensure that our clients stay ahead of the competition with cutting-edge solutions.
                                    </p>
                                </div>
                                <div>
                                    <img src={marketsed} alt="" className="div2market" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="autocontainermob">
            <Slider {...settings}>
                {/* Content for each tab that slides automatically */}
                <div className="content-slidemob">
                    <h1 className="maindivheadingmob">MARKETING</h1>
                    <p className="paramainhead2mob">
                        Our trading company excels in connecting manufacturers with top-tier materials through a global network. We prioritize customer satisfaction by offering competitive pricing, timely delivery, and personalized service.
                    </p>
                </div>

                <div className="content-slidemob">
                    <h1 className="maindivheadingmob">INPUT SOURCING</h1>
                    <p className="paramainhead2mob">
                        We pride ourselves on sourcing the highest quality products from reliable and reputable suppliers worldwide. Our extensive network allows us to provide a diverse range of materials, ensuring we meet the specific needs of our clients.
                    </p>
                </div>

                <div className="content-slidemob">
                    <h1 className="maindivheadingmob">LOGISTICS</h1>
                    <p className="paramainhead2mob">
                        Efficient logistics are key to our success. We ensure timely and secure delivery of materials through our robust network of transportation partners. Our advanced tracking systems guarantee that every shipment reaches its destination safely and on schedule.
                    </p>
                </div>

                <div className="content-slidemob">
                    <h1 className="maindivheadingmob">MARKET SEEDING</h1>
                    <p className="paramainhead2mob">
                        Market seeding is a cornerstone of our strategy. We actively introduce innovative materials to new and emerging markets, helping to drive industry growth and development.
                    </p>
                </div>
            </Slider>
        </div>

            <div className="globalmap">
                <div className="globalheadingj1">
                    <p className="gloabheadpresence">GLOBAL PRESENCE</p>
                </div>
                <div className="globalmainmap">
                    <img src={map} alt="" className="globalmapimg" />
                </div>
                <div className="scroll-container">
                    <div className="scroll-content" ref={scrollRef}>
                        <div className="item">
                            <div className="itemheadsc">
                                <div className="dot"></div>
                                <p className="headingscollerb">PLASTIC</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="itemheadsc">
                                <div className="dot"></div>
                                <p className="headingscollerb">CHEMICAL</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="itemheadsc">
                                <div className="dot"></div>
                                <p className="headingscollerb">ADDITIVES</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="itemheadsc">
                                <div className="dot"></div>
                                <p className="headingscollerb">MASTER BATCHES</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
            <Footer />
        </>
    )
}

export default WhatWeDO;