import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";



function Packaging() {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const navigate = useNavigate()
    // Define products with specific border-radius
    const products = [
        { id: 'pet', name: 'Biaxially Oriented Polypropylene [BOPP]', borderRadius: '90px' },
        { id: 'pp', name: 'Cast Polypropylene [CPP]', borderRadius: '20px' },
        { id: 'pvc', name: 'Biaxially-Oriented Polyethylene Terephthalate [BOPET] ', borderRadius: '80px' },
        { id: 'pbt', name: 'Hand stretch film', borderRadius: '20px' },
        
    ];

    const productDetails = {
        pet: {
            items: [
                { name: 'PET Prime Grade Chips IV 0.84', borderRadius: '48px' },
                { name: 'PET Prime Grade Chips IV 0.80', borderRadius: '10px' },
                { name: 'PET Prime Grade Chips IV 0.76', borderRadius: '48px' },
                { name: 'PET High IV Chips', borderRadius: '10px' },
                { name: 'PET Amorphous Chips', borderRadius: '48px' },
                { name: 'PET Lumps', borderRadius: '10px' },
                { name: 'PET Sheet Grinding', borderRadius: '48px' },

                { name: 'PET Powder', borderRadius: '10px' },
                { name: 'Polyester Cationic Chips', borderRadius: '48px' },

                { name: 'Polyester Semi Dull Chips', borderRadius: '10px' },
                { name: 'Polyester Bright Chips for Fiber Application', borderRadius: '48px' },

                { name: 'Polyester Bright Chips for Film Application', borderRadius: '10px' },
                { name: 'Next to Prime PET Chips', borderRadius: '48px' },
                { name: 'PET Floor Sweeping Chips', borderRadius: '10px' },

            ]
        },
        pp: {
            items: [
                { name: 'PP Random Copolymer', borderRadius: '10px' },
                { name: 'PP Block Copolymer', borderRadius: '15px' },
                { name: 'PP Homopolymer', borderRadius: '20px' }
            ]
        },
        pvc: {
            items: [
                { name: 'K-70', borderRadius: '10px' },
                { name: 'K-65', borderRadius: '48px' },
                { name: 'K-57', borderRadius: '10px' }
            ]
        },
        pbt: {
            items: [
                { name: 'PBT Off-spec Chips', borderRadius: '10px' },
                { name: 'PBT Lumps', borderRadius: '48px' },
                { name: 'PBT Lumps Grinding', borderRadius: '10px' }
            ]
        },
        abs: {
            items: [
                { name: 'ABS Grinding', borderRadius: '10px' },
                { name: 'ABS Off-spec Chips', borderRadius: '48px' },
            ]
        },
        ldpe: {
            items: [
                { name: 'R-LDPE Black', borderRadius: '10px' },
                { name: 'R-LDPE Grey', borderRadius: '48px' },
                { name: 'R-LDPE Natural', borderRadius: '10px' },
            ]
        },
        pe: {
            items: [
                { name: 'PE-PP Mix Clean', borderRadius: '10px' },
                { name: 'PE-PP Mix Sweeping', borderRadius: '48px' },
            ]
        },
        pmma: {
            items: [
                { name: 'PMMA Uncut Chips', borderRadius: '48px' },
            ]
        },
        san: {
            items: [
                { name: 'SAN Grinding', borderRadius: '48px' },
                { name: 'SAN Off-Grade Chips', borderRadius: '10px' },
            ]
        },
        pom: {
            items: [
                { name: 'POM Grinding', borderRadius: '48px' },
                { name: 'POM Off-spec Material', borderRadius: '10px' },
            ]
        },
    };

    return (
        <>
            <Navbar />
            <div className="polysinglepage">
                <div className="polycsssingle">
                    <div className="goback" onClick={()=>navigate(-1)}>
                        <FaArrowLeft width={"36px"} height={"36px"} />
                        <p className="backbutton">Back</p>
                    </div>
                    <div className="polymersheadingmain">
                        <p className="headingpolyinptag">PACKAGING FILMS</p>
                    </div>
                    <p className="productsmaincontpoly">CATEGORIES</p>
                    <div className="products-container">

                        <div className="products-list">
                            {products.map(product => (
                                <div
                                    key={product.id}
                                    className={`product-item ${selectedProduct === product.id ? 'active' : 'inactive'}`}
                                    onClick={() => setSelectedProduct(product.id)}
                                    style={{ borderRadius: product.borderRadius }}
                                >
                                    {product.name}
                                </div>
                            ))}
                        </div>

                        <p className="selcetdproductpoly">{selectedProduct ? products.find(p => p.id === selectedProduct).name : ''}</p>
                        <div className="product-details">
                            {selectedProduct &&
                                productDetails[selectedProduct]?.items.map((detail, index) => (
                                    <div
                                        key={index}
                                        className="detail-item"
                                        style={{ borderRadius: detail.borderRadius }}
                                    >
                                        {detail.name}
                                    </div>
                                ))}
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Packaging;