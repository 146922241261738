import { FaArrowRight } from 'react-icons/fa';
import Navbar from '../Navbar/Navbar';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


function Products() {
  let navigate=useNavigate()
    return (
        <>
        <Navbar/>
            <div className="productsdiv1">
                <div className="productdivsecondw">
                    <div className="detailsdiv">
                        <p className="productsexplorehead">EXPLORE OUR PRODUCTS</p>
                    </div>
                    <div className="ptagdetailsexploreprod">
                        <p className="mainptagdetailsexploreprod">Discover our extensive range of high-quality polymers and chemicals tailored to meet diverse industrial needs. From raw materials to specialized compounds, our products are sourced from trusted suppliers worldwide. Explore our catalog to find innovative solutions designed to enhance performance, efficiency, and sustainability in your operations.</p>
                    </div>
                    <div className="polymersandall">
                      <div className="polymers" onClick={()=>navigate("/polymers")}>
                        <p class="text">POLYMERS</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers" onClick={()=>navigate("/chemical")}>
                        <p class="text">CHEMICAL</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers" onClick={()=>navigate("/additives")}>
                        <p class="text">ADDETIVES & MASTERBACH</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers">
                        <p class="text">PACKAGING FILMS</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers">
                        <p class="text">POLYOL & MDI</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers">
                        <p class="text">HIGH PERFORMANCE TITANIUM</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers">
                        <p class="text">R-PET</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers">
                        <p class="text">PET SHEET</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                      <div className="polymers">
                        <p class="text">Ferro Titanium</p>
                        <div class="arrow">  <FaArrowRight className="arrowpoly" /></div>
                      </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Products;