import React, { useEffect, useRef, useState } from "react";
import mapImage from "../Images/img/web/googlemap.png"; // Replace with your map image

function MapContainer({ showContactForm, onContactClick, onCloseForm }) {
    const formRef = useRef(null);

    // Close form if clicked outside it
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (formRef.current && !formRef.current.contains(event.target)) {
                onCloseForm(); // Close form when clicking outside
            }
        };

        if (showContactForm) {
            document.addEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [showContactForm, onCloseForm]);

    return (
        <>
            <div id="map-container">
                <img id="map" src={mapImage} alt="Map" />

                {/* Stop the event from propagating when clicking the button */}
                {!showContactForm && (

                    <div id="contact-us">
                        <div className="headquarterspc">
                            <div className="emptyheadpc">
                            </div>
                            <p className="headpatagpc">HEADQUATERS</p>
                        </div>
                        <div className="headquarterfind">
                            <div className="findushere">
                                <p className="findusptag"><span className="perfectcolorchange">F</span>ind us here <span className="perfectcolorchange">*</span></p>
                            </div>
                            <div className="addresscontact">
                                <p className="addresspccontact">Address</p>
                                <p className="addressdetailsmaincontact">907, Remi Commercio,
                                    Link Road Ambivali Village, Near Yash Raj Studio,  Andheri West, Mumbai City, Maharashtra, 400053</p>
                            </div>
                            <div className="addresscontact">
                                <p className="addresspccontact">Phone Number</p>
                                <p className="addressdetailsmaincontact">+91 2262396000</p>
                            </div>
                            <div className="addresscontact">
                                <p className="addresspccontact">E-mail</p>
                                <p className="addressdetailsmaincontact">enquiry@styroindia.com</p>
                            </div>
                            <button
                                id="contact-usbutton"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent the click event from closing the form immediately
                                    onContactClick(); // Open the form
                                }}
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                )}

                {showContactForm && (
                    <div
                        id="contact-form"
                        ref={formRef}
                        onClick={(e) => e.stopPropagation()} // Prevent click inside form from closing it
                    >
                        <div className="insidedivcontactsecond">
                            <p className="letswork"><span className="perfectcolorchange">L</span>ET’S WORK TOGETHER</p>
                            <p className="changelets">*Let’s make something awesome together</p>
                            <form className="formcontact">
                                <div>
                                    <label className="contactemail">Email</label>
                                    <input type="email" placeholder="example@gmail.com" required />
                                </div>
                                <div>
                                    <label className="contactemail">Subject</label>
                                    <input type="text" placeholder="Please enter your subject" required />
                                </div>

                                <div>
                                    <label className="contactemail">Describe Your Needs</label>
                                    <textarea placeholder="Start Typing here..." required></textarea>
                                </div>
                                <button className="contactsubmitform" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <div id="map-containermob">
                <div className="mainmapcontmobilecheck">
                <div className="mobilemapconatiner">
                    <div className="headwuatersmob">
                        <div className="headsquaremob">

                        </div>
                        <p className="ptagheadmobquarter">HEADQUATERS</p>
                    </div>
                    <div className="findusheremob">
                        <p className="findusheremobiletext"><span className="findmobilespna">F</span>ind us here*</p>
                    </div>
                    <div className="connectingtomobile">
                        <p className="connecttomobileptag">*connecting you to cutting-edge expertise</p>
                    </div>
                </div>
                </div>
                <div className="addressmobilecheck">
                    <div className="addressdivmainwidth">
                        <p className="addressheadingmobile">Address</p>
                        <p className="addressdetailsmobileptag">907, Remi Commercio, 
                        Link Road Ambivali Village, Near Yash Raj Studio,  Andheri West, Mumbai City, Maharashtra, 400053</p>
                    </div>
                </div>
                <div className="addressmobilecheck">
                    <div className="addressdivmainwidth">
                        <p className="addressheadingmobile">Phone Number</p>
                        <p className="addressdetailsmobileptag">+91 2262396000</p>
                    </div>
                </div>
                <div className="addressmobilecheck">
                    <div className="addressdivmainwidth">
                        <p className="addressheadingmobile">E-mail</p>
                        <p className="addressdetailsmobileptag">enquiry@styroindia.com</p>
                    </div>
                </div>
                <div>
                <img  src={mapImage} alt="Map" />
                </div>
            </div>
        </>
    );
}

export default MapContainer;