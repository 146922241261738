import React, { useState } from "react";

function Contact({ onClose }) {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ email, subject, message });
    alert("Form Submitted!");
    // Reset form after submission
    setEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <div id="contact-form-overlay">
      <div id="contact-form-container">
        <h2>Let’s Work Together</h2>
        <form onSubmit={handleSubmit}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="example@gmail.com"
            required
          />

          <label>Subject</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Enter your subject"
            required
          />

          <label>Describe Your Needs</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Describe your needs"
            required
          ></textarea>

          <button type="submit">Submit</button>
          <button type="button" onClick={onClose}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
