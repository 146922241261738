import "../CSS/styroplast.css"
import lindien from "../Images/linkdien.png"
import instagram from "../Images/instagram.png"
import icon from "../Images/ICON ROTATING.svg"



function Footer() {
    return (
        <>
            <div className="footback">
                <div className="footlet">
                    <div className="contentdivfoot">
                        <div className="maintain10percentgap">

                        </div>
                        <div className="contentdivleft">
                            <div className="calltoactionfoot">
                                <div className="calltoactiondivfoot">

                                </div>
                                <p className="calltoactionptag">CALL TO ACTION</p>
                            </div>
                            <div className="dropterrotery">
                                <div className="dropdiv">
                                    <h3 className="drop"><span className="divdropfoot">D</span>rop us a line</h3>
                                </div>
                                <div className="icon-rotating">
                                    <svg className="icon-border-rotate" width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M38.4913 57.5072C26.5696 44.7278 46.7703 30.8834 58.3609 25.5586L51.7394 36.7406C42.4669 44.4083 57.8106 49.5201 66.6415 51.1175C77.2386 54.9513 57.8106 69.7542 46.772 76.6764C50.7459 71.5646 51.7394 64.9619 51.7394 62.2995C49.0901 46.9642 41.8035 52.7149 38.4913 57.5072Z" fill="#EFB631" />
                                        <path d="M59.8037 5.40163C59.8186 4.95948 59.6704 4.59262 59.3588 4.30107C59.0506 4.00998 58.6222 3.82571 58.0736 3.74824C57.6879 3.69378 57.3488 3.70525 57.0564 3.78266C56.764 3.86007 56.5305 3.9882 56.3561 4.16705C56.1816 4.34589 56.0746 4.5631 56.0351 4.81866C56.0051 5.03146 56.0272 5.22281 56.1016 5.3927C56.1793 5.56306 56.2952 5.71508 56.4493 5.84875C56.6038 5.9791 56.779 6.0954 56.9748 6.19766C57.1707 6.29992 57.3698 6.39078 57.5722 6.47023L58.496 6.84484C58.8691 6.98908 59.2227 7.16279 59.5569 7.36598C59.8944 7.56964 60.188 7.8078 60.4378 8.08046C60.6909 8.35359 60.8773 8.66648 60.9971 9.01914C61.1168 9.37179 61.1455 9.76924 61.0831 10.2115C60.9986 10.81 60.7712 11.3154 60.401 11.7277C60.0312 12.1368 59.539 12.4284 58.9243 12.6028C58.3134 12.7743 57.5989 12.8023 56.781 12.6868C55.9863 12.5746 55.3137 12.3541 54.7632 12.0254C54.2161 11.6972 53.8112 11.2738 53.5487 10.7551C53.2895 10.237 53.1945 9.63686 53.2637 8.95479L55.0842 9.21184C55.0605 9.57137 55.1285 9.8828 55.288 10.1461C55.4475 10.4094 55.6737 10.6211 55.9665 10.7811C56.2626 10.9416 56.6035 11.0491 56.9892 11.1036C57.3916 11.1604 57.7525 11.1503 58.0719 11.0733C58.3952 10.9935 58.6586 10.8577 58.862 10.666C59.0659 10.471 59.1901 10.2274 59.2348 9.93524C59.269 9.66876 59.2219 9.43828 59.0933 9.24378C58.9653 9.04595 58.7714 8.86937 58.5119 8.71402C58.2561 8.55582 57.952 8.40435 57.5994 8.2596L56.4795 7.79626C55.6688 7.46136 55.05 7.03994 54.6232 6.53202C54.2002 6.02124 54.0403 5.40009 54.1436 4.66858C54.2286 4.06675 54.4659 3.56273 54.8556 3.15653C55.2487 2.7508 55.7454 2.46315 56.3459 2.29359C56.9468 2.12069 57.6047 2.08472 58.3196 2.18566C59.0445 2.28801 59.6617 2.50403 60.1714 2.83373C60.6848 3.16058 61.0663 3.56884 61.316 4.05853C61.5661 4.5449 61.6555 5.0764 61.5843 5.65304L59.8037 5.40163Z" fill="#EFB631" />
                                        <path d="M67.4758 5.79977L68.1264 4.3747L75.6137 7.79254L74.9632 9.21761L72.0626 7.89357L68.4294 15.8529L66.7431 15.0831L70.3764 7.12382L67.4758 5.79977Z" fill="#EFB631" />
                                        <path d="M80.9799 10.9353L82.563 12.3311L81.4378 17.4231L81.5134 17.4897L86.4243 15.7356L88.0074 17.1314L80.9154 19.5009L78.3703 22.3875L76.9724 21.1549L79.5174 18.2683L80.9799 10.9353Z" fill="#EFB631" />
                                        <path d="M83.2637 27.2725L92.0086 21.8003L94.0607 25.0796C94.4811 25.7515 94.717 26.3881 94.7686 26.9897C94.822 27.594 94.7111 28.1447 94.4358 28.6418C94.1652 29.1399 93.7509 29.5636 93.193 29.9127C92.6322 30.2636 92.0706 30.4487 91.5082 30.4678C90.9505 30.4881 90.4149 30.338 89.9014 30.0175C89.3908 29.6953 88.9253 29.1983 88.5049 28.5265L87.0433 26.1908L88.3585 25.3679L89.6865 27.49C89.9323 27.8829 90.1876 28.1707 90.4526 28.3535C90.7203 28.5346 90.997 28.6189 91.2824 28.6067C91.5725 28.5955 91.8727 28.4928 92.183 28.2987C92.4933 28.1045 92.7197 27.8776 92.8623 27.6181C93.0078 27.3567 93.059 27.0652 93.0161 26.7434C92.9761 26.4199 92.8323 26.0603 92.5847 25.6646L91.6762 24.2128L84.255 28.8567L83.2637 27.2725ZM90.0532 29.3105L87.4454 33.955L86.3392 32.1872L88.971 27.5812L90.0532 29.3105Z" fill="#EFB631" />
                                        <path d="M96.3443 44.7278C95.2676 45.0038 94.2937 45.0386 93.4227 44.8321C92.5557 44.628 91.83 44.2317 91.2458 43.6431C90.6623 43.0578 90.2584 42.3276 90.0341 41.4526C89.8097 40.5776 89.812 39.7415 90.0409 38.9444C90.274 38.1497 90.721 37.4527 91.382 36.8534C92.047 36.2564 92.9163 35.8203 93.9897 35.5451C95.0664 35.269 96.0382 35.233 96.9052 35.4371C97.7763 35.6436 98.5036 36.0395 99.087 36.6249C99.6713 37.2135 100.076 37.9453 100.3 38.8203C100.524 39.6953 100.522 40.5297 100.292 41.3235C100.063 42.1207 99.6159 42.8177 98.9508 43.4146C98.2898 44.014 97.421 44.4517 96.3443 44.7278ZM95.8776 42.9078C96.6355 42.7135 97.2443 42.4309 97.7038 42.0599C98.1675 41.6915 98.4793 41.2648 98.6393 40.7801C98.8025 40.2945 98.8145 39.7801 98.6752 39.2369C98.5359 38.6937 98.2779 38.2485 97.9011 37.9014C97.5276 37.5534 97.0486 37.3278 96.464 37.2246C95.8835 37.1239 95.2143 37.1707 94.4564 37.365C93.6985 37.5594 93.0877 37.8408 92.6241 38.2093C92.1645 38.5802 91.8531 39.0084 91.6899 39.494C91.5299 39.9788 91.5196 40.4928 91.6589 41.036C91.7981 41.5792 92.0545 42.0248 92.428 42.3727C92.8048 42.7199 93.2834 42.9438 93.8639 43.0446C94.4485 43.1477 95.1197 43.1022 95.8776 42.9078Z" fill="#EFB631" />
                                        <path d="M91.7553 49.9224L102.056 50.4847L101.845 54.3474C101.802 55.1387 101.618 55.7946 101.294 56.315C100.969 56.8387 100.541 57.2239 100.01 57.4707C99.4816 57.721 98.8906 57.8283 98.2367 57.7926C97.5762 57.7565 96.997 57.5854 96.4992 57.279C96.0013 56.9727 95.6197 56.5399 95.3543 55.9806C95.0922 55.4215 94.9829 54.7446 95.0263 53.9499L95.1661 51.3899L96.7001 51.4736L96.574 53.7822C96.5488 54.2449 96.6086 54.6282 96.7534 54.9321C96.8982 55.2359 97.1076 55.466 97.3816 55.6222C97.6554 55.7817 97.975 55.8715 98.3405 55.8914C98.706 55.9114 99.0318 55.8569 99.318 55.7279C99.604 55.6022 99.8323 55.3944 100.003 55.1044C100.176 54.818 100.276 54.4417 100.302 53.9756L100.395 52.2656L91.6535 51.7883L91.7553 49.9224Z" fill="#EFB631" />
                                        <path d="M90.7241 61.0682L100.506 64.3453L99.912 66.1173L91.6159 63.3378L90.1726 67.6459L88.6872 67.1483L90.7241 61.0682Z" fill="#EFB631" />
                                        <path d="M86.4967 71.7405L87.6258 70.0962L94.074 78.9296L92.7681 80.8313L82.2054 77.9898L83.3346 76.3454L91.6542 78.7161L91.6999 78.6497L86.4967 71.7405ZM89.794 74.0841L86.7145 78.5686L85.4771 77.7189L88.5566 73.2344L89.794 74.0841Z" fill="#EFB631" />
                                        <path d="M80.4327 89.6077C80.7456 89.9204 81.115 90.0625 81.5407 90.0338C81.9638 90.0073 82.3909 89.82 82.822 89.4719C83.1251 89.2272 83.3481 88.9716 83.4912 88.7051C83.6342 88.4386 83.6999 88.1805 83.6883 87.9309C83.6767 87.6813 83.591 87.4549 83.4312 87.2516C83.2962 87.0844 83.1412 86.97 82.9663 86.9083C82.7888 86.8488 82.5986 86.8297 82.3957 86.8511C82.1949 86.8751 81.9904 86.9237 81.782 86.997C81.5736 87.0703 81.3712 87.1538 81.175 87.2475L80.2706 87.6669C79.9106 87.8411 79.5423 87.9809 79.1657 88.0864C78.7865 88.1941 78.412 88.2461 78.0422 88.2425C77.6699 88.241 77.3139 88.1637 76.9745 88.0105C76.635 87.8573 76.325 87.607 76.0444 87.2595C75.6647 86.7892 75.4506 86.278 75.4019 85.726C75.3554 85.1766 75.4783 84.6178 75.7705 84.0495C76.0622 83.486 76.5294 82.9448 77.1721 82.4259C77.7966 81.9217 78.4168 81.5807 79.0327 81.4028C79.6461 81.2269 80.2318 81.2201 80.79 81.3823C81.3456 81.5466 81.8491 81.8868 82.3003 82.4029L80.8698 83.5579C80.6232 83.2952 80.3492 83.1322 80.0479 83.0691C79.7466 83.0059 79.4375 83.0267 79.1207 83.1314C78.8013 83.2383 78.49 83.4141 78.1869 83.6588C77.8708 83.914 77.6318 84.1846 77.47 84.4707C77.3076 84.7614 77.2271 85.0465 77.2284 85.3261C77.2317 85.6082 77.3249 85.8653 77.5079 86.0973C77.6793 86.3042 77.8799 86.4271 78.1098 86.4659C78.3418 86.5074 78.6031 86.4863 78.8938 86.4026C79.184 86.3237 79.5023 86.2048 79.8488 86.0459L80.9518 85.5438C81.75 85.18 82.4803 85.0154 83.1428 85.0502C83.8049 85.0897 84.3679 85.3969 84.832 85.9717C85.2138 86.4446 85.4201 86.9621 85.451 87.5242C85.4792 88.0884 85.3503 88.6477 85.0644 89.2023C84.7805 89.7595 84.3577 90.2648 83.796 90.7184C83.2264 91.1782 82.6472 91.4819 82.0584 91.6292C81.469 91.7813 80.9102 91.7815 80.382 91.6296C79.8558 91.4804 79.4063 91.183 79.0336 90.7373L80.4327 89.6077Z" fill="#EFB631" />
                                        <path d="M75.0443 94.8022L75.6687 96.2389L68.1203 99.5196L67.4959 98.0829L70.4201 96.812L66.9326 88.7878L68.6326 88.0489L72.1201 96.0732L75.0443 94.8022Z" fill="#EFB631" />
                                        <path d="M58.0025 96.4046L57.7441 94.9259L62.3188 94.1262L62.5773 95.6048L58.0025 96.4046Z" fill="#EFB631" />
                                        <path d="M46.9437 99.0025C46.9581 99.4447 47.1303 99.801 47.4604 100.071C47.7872 100.341 48.2269 100.497 48.7795 100.538C49.1679 100.567 49.5055 100.533 49.7921 100.436C50.0788 100.34 50.3032 100.196 50.4655 100.006C50.6277 99.8163 50.7201 99.5925 50.7426 99.3349C50.7585 99.1206 50.7237 98.9311 50.6383 98.7665C50.5495 98.6017 50.4238 98.4577 50.2612 98.3345C50.0984 98.2147 49.9159 98.1102 49.7137 98.0211C49.5116 97.932 49.3069 97.8545 49.0996 97.7887L48.153 97.476C47.7713 97.3567 47.407 97.2068 47.0601 97.0262C46.7098 96.8453 46.4011 96.6271 46.1338 96.3715C45.8632 96.1158 45.6565 95.8159 45.5136 95.4719C45.3708 95.128 45.3159 94.7333 45.3489 94.2879C45.3937 93.6851 45.5871 93.1657 45.9292 92.7298C46.2711 92.2972 46.743 91.9736 47.3448 91.759C47.943 91.5475 48.6541 91.4723 49.4779 91.5334C50.2782 91.5928 50.9639 91.7683 51.5349 92.0598C52.1026 92.3511 52.5346 92.7468 52.8308 93.2469C53.1237 93.7468 53.2582 94.3394 53.2343 95.0245L51.4008 94.8885C51.4006 94.5281 51.3122 94.2219 51.1356 93.9697C50.959 93.7175 50.7194 93.5213 50.4166 93.381C50.1105 93.2404 49.7633 93.1557 49.3748 93.1269C48.9696 93.0968 48.6101 93.1308 48.2964 93.2287C47.9792 93.3298 47.7254 93.4827 47.5351 93.6874C47.3445 93.8955 47.2367 94.1468 47.2115 94.4412C47.1949 94.7094 47.2572 94.9363 47.3983 95.1218C47.5392 95.3107 47.7443 95.4741 48.0135 95.612C48.2792 95.7529 48.5927 95.8839 48.9542 96.005L50.1022 96.3932C50.9333 96.6738 51.5786 97.0533 52.0381 97.5319C52.494 98.0136 52.6946 98.6228 52.6399 99.3595C52.5949 99.9657 52.3915 100.484 52.0295 100.915C51.6642 101.346 51.1875 101.666 50.5996 101.875C50.0114 102.087 49.3573 102.167 48.6373 102.113C47.9073 102.059 47.2771 101.884 46.7468 101.589C46.2128 101.297 45.8051 100.915 45.5236 100.443C45.2419 99.974 45.1175 99.4496 45.1504 98.8695L46.9437 99.0025Z" fill="#EFB631" />
                                        <path d="M39.2684 99.1176L38.7144 100.583L31.0157 97.6722L31.5697 96.2069L34.5521 97.3345L37.6463 89.1505L39.3801 89.806L36.286 97.99L39.2684 99.1176Z" fill="#EFB631" />
                                        <path d="M25.4503 94.8918L23.7784 93.6038L24.5645 88.4485L24.4846 88.387L19.7004 90.462L18.0285 89.174L24.9484 86.3407L27.297 83.2922L28.7734 84.4296L26.4247 87.4782L25.4503 94.8918Z" fill="#EFB631" />
                                        <path d="M22.0897 78.7392L13.7266 84.7787L11.4618 81.6426C10.9978 81.0001 10.7202 80.3805 10.6288 79.7837C10.5356 79.1842 10.6098 78.6273 10.8515 78.1131C11.0885 77.5981 11.4738 77.148 12.0073 76.7627C12.5437 76.3754 13.0917 76.1535 13.6516 76.0971C14.2068 76.04 14.7512 76.1543 15.2848 76.44C15.8156 76.7276 16.313 77.1927 16.777 77.8352L18.3901 80.0689L17.1324 80.9772L15.6667 78.9477C15.3954 78.572 15.1215 78.3017 14.8451 78.1368C14.5659 77.974 14.2843 77.9081 14.0002 77.9392C13.7115 77.9696 13.4188 78.092 13.1221 78.3062C12.8253 78.5205 12.6144 78.7619 12.4893 79.0304C12.3615 79.3008 12.3297 79.5951 12.3938 79.9133C12.4552 80.2335 12.6225 80.5827 12.8958 80.9612L13.8985 82.3496L20.9957 77.2242L22.0897 78.7392ZM15.1802 77.1555L17.4746 72.3484L18.6954 74.039L16.3745 78.8093L15.1802 77.1555Z" fill="#EFB631" />
                                        <path d="M7.88086 62.2042C8.93659 61.8565 9.90595 61.7565 10.7889 61.9041C11.6677 62.0495 12.4183 62.3963 13.0407 62.9443C13.6621 63.4892 14.1141 64.1906 14.3966 65.0486C14.6792 65.9066 14.733 66.7409 14.5581 67.5516C14.3789 68.3601 13.9797 69.0855 13.3604 69.7279C12.7369 70.3681 11.8988 70.8616 10.8463 71.2082C9.79057 71.5559 8.82334 71.657 7.9446 71.5116C7.06162 71.364 6.30943 71.0178 5.68805 70.4729C5.06561 69.9248 4.61311 69.2218 4.33053 68.3638C4.04796 67.5059 3.99467 66.6731 4.17066 65.8656C4.34561 65.055 4.74484 64.3295 5.36835 63.6893C5.98763 63.0469 6.82514 62.5519 7.88086 62.2042ZM8.46859 63.9887C7.72544 64.2335 7.13704 64.5563 6.7034 64.9572C6.26552 65.356 5.98304 65.8026 5.85596 66.297C5.72569 66.7924 5.74827 67.3065 5.9237 67.8391C6.09912 68.3718 6.38643 68.7986 6.78562 69.1197C7.18161 69.4418 7.67471 69.6347 8.2649 69.6984C8.85086 69.76 9.51541 69.6684 10.2586 69.4237C11.0017 69.1789 11.5922 68.8572 12.0301 68.4584C12.4638 68.0575 12.7457 67.6093 12.876 67.1139C13.0031 66.6195 12.9789 66.1059 12.8035 65.5733C12.628 65.0406 12.3423 64.6133 11.9463 64.2911C11.5471 63.9701 11.0546 63.7788 10.4686 63.7172C9.87842 63.6535 9.21175 63.744 8.46859 63.9887Z" fill="#EFB631" />
                                        <path d="M12.114 56.7461L1.79884 56.8688L1.75284 53.0006C1.74341 52.2082 1.88325 51.5416 2.17235 51.0008C2.46141 50.4567 2.86285 50.0439 3.37668 49.7624C3.88711 49.4776 4.46971 49.3313 5.12448 49.3235C5.78596 49.3156 6.37524 49.448 6.8923 49.7206C7.40935 49.9932 7.81886 50.3997 8.12082 50.9402C8.41941 51.4806 8.57344 52.1488 8.58291 52.9446L8.61339 55.5083L7.0772 55.5265L7.04971 53.2147C7.0442 52.7513 6.9591 52.3728 6.79441 52.0793C6.62972 51.7857 6.40551 51.5701 6.12178 51.4324C5.838 51.2914 5.51312 51.223 5.14712 51.2274C4.78112 51.2317 4.45963 51.3078 4.18265 51.4555C3.90563 51.5998 3.69166 51.8223 3.54076 52.123C3.38646 52.4204 3.31208 52.8024 3.31763 53.2691L3.33799 54.9816L12.0918 54.8775L12.114 56.7461Z" fill="#EFB631" />
                                        <path d="M12.4014 45.522L2.42211 42.9082L2.89559 41.1005L11.3595 43.3173L12.5106 38.9221L14.026 39.319L12.4014 45.522Z" fill="#EFB631" />
                                        <path d="M15.908 34.5796L14.8902 36.2951L7.87138 27.908L9.04851 25.9239L19.7761 28.0599L18.7583 29.7754L10.2999 27.9608L10.2588 28.0301L15.908 34.5796ZM12.4628 32.4594L15.2386 27.7809L16.5295 28.5468L13.7538 33.2253L12.4628 32.4594Z" fill="#EFB631" />
                                        <path d="M20.7768 16.3552C20.4439 16.0638 20.066 15.9464 19.6431 16.0032C19.2227 16.0576 18.8089 16.2727 18.4017 16.6485C18.1155 16.9127 17.9098 17.1825 17.7847 17.4579C17.6596 17.7333 17.611 17.9951 17.6391 18.2434C17.6672 18.4916 17.7677 18.7119 17.9406 18.9042C18.0863 19.0621 18.2485 19.1661 18.4271 19.216C18.6082 19.2637 18.7992 19.2701 19.0002 19.2354C19.199 19.1982 19.3999 19.1362 19.603 19.0493C19.8061 18.9624 20.0024 18.8657 20.1921 18.7592L21.0667 18.281C21.4145 18.0834 21.7728 17.9196 22.1416 17.7894C22.5129 17.6569 22.8831 17.5803 23.2523 17.5594C23.6239 17.5363 23.9842 17.59 24.333 17.7204C24.6819 17.8508 25.0077 18.0802 25.3106 18.4084C25.7206 18.8526 25.968 19.3485 26.053 19.8961C26.1357 20.4412 26.0501 21.0069 25.7961 21.5932C25.5422 22.1747 25.1118 22.7456 24.5047 23.3059C23.9149 23.8502 23.3186 24.2315 22.7158 24.4497C22.1154 24.6656 21.5314 24.7111 20.9637 24.5862C20.3984 24.459 19.8736 24.1528 19.3893 23.6676L20.7404 22.4207C21.0038 22.6665 21.2879 22.811 21.5927 22.8541C21.8976 22.8972 22.2046 22.856 22.5138 22.7306C22.8255 22.6029 23.1244 22.4069 23.4107 22.1427C23.7093 21.8672 23.9299 21.5813 24.0724 21.2853C24.2152 20.9844 24.2767 20.6946 24.257 20.4158C24.235 20.1345 24.1251 19.8841 23.9271 19.6647C23.7424 19.4695 23.5341 19.3602 23.3022 19.3366C23.068 19.3106 22.8086 19.3489 22.5241 19.4516C22.2397 19.5495 21.93 19.6892 21.5947 19.8706L20.5273 20.4445C19.7549 20.8603 19.0371 21.0727 18.3737 21.0817C17.7105 21.086 17.1284 20.8167 16.6273 20.2738C16.2151 19.8272 15.9751 19.3244 15.9072 18.7656C15.8417 18.2046 15.9334 17.6379 16.1821 17.0657C16.4285 16.4909 16.817 15.9588 17.3475 15.4691C17.8855 14.9726 18.4434 14.6314 19.0212 14.4454C19.5992 14.2547 20.1567 14.2177 20.6939 14.3343C21.2287 14.4484 21.6969 14.7155 22.0983 15.1356L20.7768 16.3552Z" fill="#EFB631" />
                                        <path d="M25.7925 10.8262L25.0732 9.43457L32.385 5.65556L33.1042 7.0472L30.2717 8.51117L34.2889 16.2838L32.6422 17.1349L28.625 9.36225L25.7925 10.8262Z" fill="#EFB631" />
                                        <path d="M42.7012 8.08156L43.0578 9.53962L38.5466 10.643L38.19 9.18496L42.7012 8.08156Z" fill="#EFB631" />
                                    </svg>
                                </div>
                                {/* <img className="icon-rotating" src={icon} alt="icon-rotating" /> */}
                            </div>
                            <div className="dropusmagic">
                                <p className="magicdropus">*and we’ll work our magic</p>
                            </div>
                            <div className="mailtofoot">
                                <p className="mailstyrofoot">enquiry@styroindia.com</p>
                            </div>
                        </div>
                        <div className="contentdivright">

                        </div>
                    </div>
                    <div className="downdivmobile">
                        <div className="twoicionandcall">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="lighticon"><path fill="#fff" d="M1.775 16.25C-4.04 9.75 5.814 2.708 11.467 0l-3.23 5.688c-4.523 3.9 2.962 6.5 7.269 7.312 5.169 1.95-4.307 9.48-9.692 13 1.939-2.6 2.423-5.958 2.423-7.312-1.292-7.8-4.846-4.875-6.462-2.438"></path></svg>
                            <p className="nameofcompany">STYROPLAST</p>
                        </div>
                        <div className="linkinsta">
                            <img className="linkdinfootlink" src={lindien} alt="" />
                            <img className="instagramfootlink" src={instagram} alt="" />
                        </div>
                        <div className="madeinindiafooter">
                            <p className="madeinindiaptag">Made in India</p>
                        </div>
                        <div className="linkdateyearfooter">
                            <p className="yearfooter201524">© 2015-2024 Styroplast</p>
                        </div>
                    </div>
                    <div className="fourdivsetfoot">
                        <div className="onedivfoot">

                        </div>
                        <div className="twodivfoot">
                            <div className="twostryoicomlabel">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="26" fill="none" class="h-6 w-6 mt-0"><path fill="#fff" d="M1.775 16.25C-4.04 9.75 5.814 2.708 11.467 0l-3.23 5.688c-4.523 3.9 2.962 6.5 7.269 7.312 5.169 1.95-4.307 9.48-9.692 13 1.939-2.6 2.423-5.958 2.423-7.312-1.292-7.8-4.846-4.875-6.462-2.438"></path></svg>
                                <p className="namelabelfoottwodiv">STYROPLAST</p>
                            </div>
                            <div className="madeinindiafoot">
                                <p className="madeinindiaend">Made in India</p>
                            </div>
                        </div>
                        <div className="threedivfoot">
                            <div className="mainthreefoot">
                                <div className="linkyearandfacebook">
                                    <div className="linkinstadivfoot">
                                        <img className="linkdienfoot" src={lindien} alt="" />
                                        <img className="instagramfoot" src={instagram} alt="" />
                                    </div>
                                    <div className="linkdateyearfoot">
                                        <p className="yearfooter2015">© 2015-2024 Styroplast</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fourdivfoot">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;