import Footer from './Components/Home/Footer';
import Home from './Components/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import logo from './logo.svg';
import './App.css';
import WhatWeDO from './Components/Home/WhatweDo';
import Products from './Components/Home/Products';
import Contact from './Components/Home/Contact';
import ContactAll from './Components/Home/ContactAll';
import { Route, Routes } from 'react-router-dom';
import Polymers from './Components/Home/Polmers';
import Chemical from './Components/Home/Chemical';
import Additives from './Components/Home/Additives';
import Packaging from './Components/Home/Packging';

function App() {
  return (
    <div className="App">
   
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/whatwedo' element={<WhatWeDO/>}/>
      <Route path='/product' element={<Products/>}/>
      <Route path='/contact' element={<ContactAll/>}/>
      <Route path='/polymers' element={<Polymers/>}/>
      <Route path='/chemical' element={<Chemical/>}/>
      <Route path='/additives' element={<Additives/>}/>
      <Route path='/packaging' element={<Packaging/>}/>
     </Routes>
    </div>
  );
}

export default App;
