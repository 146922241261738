import React, { useEffect, useState } from 'react';
import '../CSS/styroplast.css';
import logo from "../Images/logo.png";
import menu from "../Images/menu.png";
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate=useNavigate()

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className='navbartop'>
            <header className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
                <div className="navbar-brand">
                    <img onClick={()=>navigate("/")} src={logo} alt="STPL Logo" className="navbar-logo" />
                    <span className='navbarspan'>STPL</span>
                </div>
                <nav className="navbar-links">
                    <p onClick={()=>navigate("/whatwedo")}>What We Do</p>
                    <p onClick={()=>navigate("/product")}>Products</p>
                    <p onClick={()=>navigate("/contact")}>Contact</p>
                </nav>
                <div className='navbarlinemadebyham'>
                    <nav className='navbarmobileham'>
                        <img src={menu} alt="menubar" />
                    </nav>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
