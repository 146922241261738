import { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import Navbar from "../Navbar/Navbar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";



function Additives() {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedProduct1, setSelectedProduct1] = useState(null);
    const navigate = useNavigate()
    // Define products with specific border-radius
    const products = [
        { id: 'pet', name: 'PP Masterbatches For Film Application', borderRadius: '20px' },
        { id: 'pp', name: 'PET masterbatches for film & fiber', borderRadius: '80px' },
        { id: 'pvc', name: 'PE Masterbatch For Film Application', borderRadius: '20px' },
        { id: 'pbt', name: 'Additives', borderRadius: '80px' },

    ];

    const productDetails = {
        pet: {
            items: [
                { name: 'Antiblock Masterbatches', borderRadius: '80px' },
                { name: 'Slip & Slip-Antiblock Masterbatches', borderRadius: '20px' },
                { name: 'Antistatic Masterbatches', borderRadius: '80px' },
                { name: 'Slip-Antistatic Masterbatches', borderRadius: '20px' },
                { name: 'Stiffeners/Polymer Modifier Masterbatches', borderRadius: '80px' },
                { name: 'Matt Compounds Anti-Oxidants Masterbatches', borderRadius: '20px' },
                { name: 'UV Stabiliser Masterbatches', borderRadius: '80px' },

                { name: 'Antioxidant Masterbatches', borderRadius: '20px' },
                { name: 'Cavitating Masterbatches', borderRadius: '80px' },

                { name: 'Processing Aid', borderRadius: '20px' },
                { name: 'Anti-Fog Masterbatches', borderRadius: '80px' },

                { name: 'Nucleating Agent Masterbatches', borderRadius: '20px' },
                { name: 'Optical Brightener Masterbatches', borderRadius: '80px' },
                { name: 'White Masterbatches', borderRadius: '20px' },

            ]
        },
        pp: {
            items: [
                { name: 'Antiblock Masterbatches', borderRadius: '80px' },
                { name: 'Anti-oxidant Masterbatches', borderRadius: '20px' },
                { name: 'Optical Brightener Masterbatches', borderRadius: '80px' },
                { name: 'White Masterbatches', borderRadius: '20px' },
                { name: 'Black Masterbatches', borderRadius: '80px' },
            ]
        },
        pvc: {
            items: [
                { name: 'Anti-Block Masterbatches', borderRadius: '80px' },
                { name: 'Slip – Anti Block Combination Masterbatches', borderRadius: '20px' },
                { name: 'Anti-Fog Masterbatches', borderRadius: '80px' },
                { name: 'Processing Aids', borderRadius: '20px' },
                { name: 'UV Masterbatches', borderRadius: '80px' },
                { name: 'White Masterbatches', borderRadius: '20px' },
                { name: 'Anti-Oxidant Masterbatches', borderRadius: '80px' },
            ]
        },
        pbt: {
            items: [
                { name: 'Antioxidants', borderRadius: '80px' },
                { name: 'UV/Light Stabilizers', borderRadius: '20px' },
                { name: 'UV Absorbers', borderRadius: '80px' },
                { name: 'Optical Brightness', borderRadius: '20px' },
                { name: 'Other Additive Productst', borderRadius: '80px' },
            ]
        },

    };


    const data = [
        {
            id: 1,
            grades: "BLOCK 05A",
            active: "5%",
            base: "Homo PP",
            description: "Synthetic silica anti-block for plain film"
        },
        {
            id: 2,
            grades: "BLOCK 102A",
            active: "10%",
            base: "Homo PP",
            description: "Synthetic silica anti-block for plain film"
        },
        {
            id: 3,
            grades: "S-BLOCK 101AA",
            active: "5%",
            base: "Co PP",
            description: "Synthetic silica anti-block for heatsealable film"
        },
        {
            id: 4,
            grades: "S-BLOCK 102AA",
            active: "10%",
            base: "Co PP",
            description: "Synthetic silica anti-block for heatsealable film"
        },
        {
            id: 5,
            grades: "BLOCK 3301A",
            active: "5%",
            base: "Homo PP",
            description: "Low haze anti-block for high transparent film, printing film"
        },
        {
            id: 6,
            grades: "BLOCK 3301AA",
            active: "5%",
            base: "Co PP",
            description: "Low haze anti-block for high transparent film, printing film, and metallizable film"
        },
        {
            id: 7,
            grades: "BLOCK 2C221A",
            active: "5%",
            base: "Co PP",
            description: "Non-migratory slip/antiblock for very thin skin layer, metalizable and heatseal film"
        },
        {
            id: 8,
            grades: "BLOCK 2C01A",
            active: "5%",
            base: "Co PP",
            description: "Non-migratory slip/antiblock for standard skin layer, metalizable and heatseal film"
        },
        {
            id: 9,
            grades: "BLOCK 2C41A",
            active: "5%",
            base: "Co PP",
            description: "Non-migratory slip/antiblock for thick skin layer, metalizable and heat sealable film"
        },
        {
            id: 10,
            grades: "BLOCK 2C811A",
            active: "5%",
            base: "Co PP",
            description: "Non-migratory slip/antiblock for very thick skin layer, metalizable and heat sealable film"
        },
        {
            id: 11,
            grades: "BLOCK MT2A",
            active: "10%",
            base: "Co PP",
            description: "Special anti-block for metallized film"
        },
        {
            id: 12,
            grades: "BLOCK OJ 6AA",
            active: "5%",
            base: "Co PP",
            description: "Special anti-block for metallized film"
        },
        {
            id: 13,
            grades: "BLOCK 5011AA",
            active: "5%",
            base: "Co PP",
            description: "Low cof antiblock for heatsealable films"
        },
        {
            id: 14,
            grades: "BLOCK 5031AA",
            active: "10%",
            base: "Co PP",
            description: "Low cof antiblock for heatsealable films"
        },


    ]

    const dataslip = [
        {
            id: 1,
            grades: "SLIP X6A",
            active: "6%",
            base: "Homo PP",
            description: "Standard slip agents for core layer"
        },
        {
            id: 2,
            grades: "SLIP X12A",
            active: "12%",
            base: "Co PP",
            description: "Standard slip agents for core layer, double Concentration"
        },
        {
            id: 3,
            grades: "SLIP X66AA",
            active: "6%",
            base: "Co PP",
            description: "Standard slip agents for skin layer, low cof"
        },
        {
            id: 4,
            grades: "SLIP 3101A",
            active: "5%",
            base: "Homo PP",
            description: "High molecular weight slip agents, long lasting effect"
        },
    ]

    const dataslip1 = [
        {
            id: 1,
            grades: "BS 111AA",
            active: "10%",
            base: "Co PP",
            description: "Combination of synthetica silica & standard slip agents for skin layer"
        },
        {
            id: 2,
            grades: "BS 122AA",
            active: "20%",
            base: "Co PP",
            description: "Double concentration, combination of synthetica silica & standard slip agents for skin layer"
        },


    ]

    const dataantistatic = [
        {
            id: 1,
            grades: "ASC 104A",
            active: "20%",
            base: "Homo PP",
            description: "Short and long term antistatic blend for Balanced properties, good slip properties"
        },
        {
            id: 2,
            grades: "ASC 106A",
            active: "30%",
            base: "Homo PP",
            description: "Short and long term antistatic blend for Balanced properties, good slip properties, higher concentration"
        },
        {
            id: 3,
            grades: "ASC 106A-OBA",
            active: "30%",
            base: "Homo PP",
            description: "ASC 106A with optical brightener for improved UV - curing"
        },
        {
            id: 4,
            grades: "ASC 112A",
            active: "12.5%",
            base: "Homo PP",
            description: "Antistatic blend with good optical properties, long term effect"
        },
        {
            id: 5,
            grades: "ASC 112-C30",
            active: "30%",
            base: "Homo PP",
            description: "Antistatic blend with good optical properties, long term effect, a higher concentration"
        },
    ]

    const dataantistaticslip = [
        {
            id: 1,
            grades: "COBLASC 103A",
            active: "15%",
            base: "Homo PP",
            description: "Slip and antistatic blend for hot climates, low cof, heat sealable and plain film"
        },
        {
            id: 2,
            grades: "COBLASC 206AA",
            active: "30%",
            base: "Homo PP",
            description: "Slip and antistatic blend for hot climate, low cof, heatsealable film, double concentration"
        },
        {
            id: 3,
            grades: "ASIP 4002A",
            active: "24%",
            base: "Homo PP",
            description: "Combined slip and antistatic agents, plain film"
        },
        {
            id: 4,
            grades: "COBLASC 603AA",
            active: "30%",
            base: "Homo PP",
            description: "Slip and antistatic blend for cold climate"
        },

    ]

    const datastiffiners = [
        {
            id: 1,
            grades: "STF C5010A",
            active: "50%",
            base: "Homo PP",
            description: "C5 hydrocarbon resin, improves stiffness & barrier properties"
        },
        {
            id: 2,
            grades: "STF C9010A",
            active: "50%",
            base: "Homo PP",
            description: "C9 hydrocarbon resin, cigarette film, twist wrap, improves stiffness & barrier properties, high transparency"
        },
        {
            id: 3,
            grades: "STF C9012A",
            active: "65%",
            base: "Homo PP",
            description: "C9 hydrocarbon resin, cigarette film, twist wrap, improves stiffness & barrier properties, high transparency"
        }
    ]

    const datamattcompoundanti = [
        {
            id: 1,
            grades: "MATTE N-3546",
            description: "Excellent matte properties, high haze, low gloss"
        },
        {
            id: 2,
            grades: "MATTE N-7709",
            description: "Excellent matte properties, heatsealable film, low sit"
        },

    ]

    const datauvstabilzer = [
        {
            id: 1,
            grades: "UV 104A",
            active: "20%",
            base: "PP Homo",
            description: "UV light stabilisation, general purpose, limited food approvals"
        },
        {
            id: 2,
            grades: "UV 404A",
            active: "20%",
            base: "PP Homo",
            description: "UV light stabilisation, general purpose, FDA complaint"
        },

    ]

    const dataantioxidents = [
        {
            id: 1,
            grades: "AO 1602A",
            active: "20%",
            base: "PP Homo",
            description: "Stabilization of resin during processing, general purpose application"
        },
        {
            id: 2,
            grades: "AO 1604A",
            active: "20%",
            base: "PP Homo",
            description: "Stabilization of resin during processing, general purpose application, double concentration"
        },

    ]

    const datacaviting = [
        {
            id: 1,
            grades: "CAV 3700",
            active: "30%",
            base: "Homo PP",
            description: "Organic cavitating masterbatch, improved gloss, higher whiteness compared to standard cavitating masterbatches"
        },
        {
            id: 2,
            grades: "CAV 7400",
            active: "40%",
            base: "Homo PP",

        },

    ]

    const dataprocessingaid=[
        
    ]

    console.log(selectedProduct1)
    return (
        <>
            <Navbar />
            <div className="polysinglepage">
                <div className="polycsssingle">
                    <div className="goback" onClick={() => navigate(-1)}>
                        <FaArrowLeft width={"36px"} height={"36px"} />
                        <p className="backbutton">Back</p>
                    </div>
                    <div className="polymersheadingmain">
                        <p className="headingpolyinptag">ADDITIVES & MASTERBACH</p>
                        {!selectedProduct && (
                            <p className="customgradingaddd">*Custom grades and colours available on request</p>
                        )}
                    </div>
                    <p className="productsmaincontpoly">CATEGORIES</p>
                    <div className="products-container">

                        <div className="products-list">
                            {products.map(product => (
                                <div
                                    key={product.id}
                                    className={`product-item ${selectedProduct === product.id ? 'active' : 'inactive'}`}
                                    onClick={() => {
                                        setSelectedProduct(product.id)
                                        setSelectedProduct1("")
                                    }}
                                    style={{ borderRadius: product.borderRadius }}
                                >
                                    {product.name}
                                </div>
                            ))}
                        </div>

                        <p className="selcetdproductpoly">{selectedProduct ? "Sub categories" : ''}</p>
                        <div className="product-details">
                            {selectedProduct &&
                                productDetails[selectedProduct]?.items.map((detail, index) => (
                                    <div
                                        key={index}
                                        className={`detail-itemmain ${selectedProduct1 === detail.name ? 'active' : 'inactive'}`}
                                        style={{ borderRadius: detail.borderRadius }}
                                        onClick={() => setSelectedProduct1(detail.name)}
                                    >
                                        {detail.name}
                                    </div>
                                ))}
                        </div>


                    </div>

                </div>
            </div>
            <div className="antiblockmakewidth">
                {
                    selectedProduct1 === "Antiblock Masterbatches" ?
                        <>
                            <div>
                                <p className="antiblockptag">ANTIBLOCK</p>
                                <div>
                                    <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th>GRADES</th>
                                                <th>ACTIVE CONTENT</th>
                                                <th>BASE POLYMER</th>
                                                <th>DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                    <td><p className="centeralignbelow">{item.grades}</p></td>
                                                    <td><p className="centeralignbelow">{item.active}</p></td>
                                                    <td><p className="centeralignbelow">{item.base}</p></td>
                                                    <td> <p >{item.description}</p></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                        : selectedProduct1 === "Slip & Slip-Antiblock Masterbatches" ? <>
                            <div>
                                <p className="antiblockptag">SLIP & SLIP-ANTIBLOCK</p>
                                <div>
                                    <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th>GRADES</th>
                                                <th>ACTIVE CONTENT</th>
                                                <th>BASE POLYMER</th>
                                                <th>DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataslip.map((item, index) => (
                                                <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                    <td><p className="centeralignbelow">{item.grades}</p></td>
                                                    <td><p className="centeralignbelow">{item.active}</p></td>
                                                    <td><p className="centeralignbelow">{item.base}</p></td>
                                                    <td> <p >{item.description}</p></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    <p className="slipsmalltip">Slip-Antiblock Masterbatches</p>
                                    <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th>GRADES</th>
                                                <th>ACTIVE CONTENT</th>
                                                <th>BASE POLYMER</th>
                                                <th>DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataslip1.map((item, index) => (
                                                <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                    <td>{item.grades}</td>
                                                    <td>{item.active}</td>
                                                    <td>{item.base}</td>
                                                    <td>{item.description}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </> : selectedProduct1 === "Antistatic Masterbatches" ? <>
                            <div>
                                <p className="antiblockptag">ANTISTATIC</p>
                                <div>
                                    <table className="custom-table">
                                        <thead>
                                            <tr>
                                                <th>GRADES</th>
                                                <th>ACTIVE CONTENT</th>
                                                <th>BASE POLYMER</th>
                                                <th>DESCRIPTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataantistatic.map((item, index) => (
                                                <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                    <td>{item.grades}</td>
                                                    <td>{item.active}</td>
                                                    <td>{item.base}</td>
                                                    <td>{item.description}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                            : selectedProduct1 === "Slip-Antistatic Masterbatches" ? <>
                                <div>
                                    <p className="antiblockptag">SLIP-ANTISTATIC</p>
                                    <div>
                                        <table className="custom-table">
                                            <thead>
                                                <tr>
                                                    <th>GRADES</th>
                                                    <th>ACTIVE CONTENT</th>
                                                    <th>BASE POLYMER</th>
                                                    <th>DESCRIPTION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataantistaticslip.map((item, index) => (
                                                    <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                        <td><p className="centeralignbelow">{item.grades}</p></td>
                                                        <td><p className="centeralignbelow">{item.active}</p></td>
                                                        <td><p className="centeralignbelow">{item.base}</p></td>
                                                        <td> <p >{item.description}</p></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                                : selectedProduct1 === "Stiffeners/Polymer Modifier Masterbatches" ? <>
                                    <div>
                                        <p className="antiblockptag">STIFFENERS/POLYMER MODIFIER</p>
                                        <div>
                                            <table className="custom-table">
                                                <thead>
                                                    <tr>
                                                        <th>GRADES</th>
                                                        <th>ACTIVE CONTENT</th>
                                                        <th>BASE POLYMER</th>
                                                        <th>DESCRIPTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datastiffiners.map((item, index) => (
                                                        <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                            <td><p className="centeralignbelow">{item.grades}</p></td>
                                                            <td><p className="centeralignbelow">{item.active}</p></td>
                                                            <td><p className="centeralignbelow">{item.base}</p></td>
                                                            <td> <p >{item.description}</p></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                                    : selectedProduct1 === "Matt Compounds Anti-Oxidants Masterbatches" ? <>
                                        <div>
                                            <p className="antiblockptag">MATT COMPOUNDS ANTI-OXIDANTS</p>
                                            <div>
                                                <table className="custom-table">
                                                    <thead>
                                                        <tr>
                                                            <th>GRADES</th>
                                                            <th>DESCRIPTION</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {datamattcompoundanti.map((item, index) => (
                                                            <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                                <td><p className="centeralignbelow">{item.grades}</p></td>
                                                                <td> <p >{item.description}</p></td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                        : selectedProduct1 === "UV Stabiliser Masterbatches" ? <>
                                            <div>
                                                <p className="antiblockptag">UV STABILISER</p>
                                                <div>
                                                    <table className="custom-table">
                                                        <thead>
                                                            <tr>
                                                                <th>GRADES</th>
                                                                <th>ACTIVE CONTENT</th>
                                                                <th>BASE POLYMER</th>
                                                                <th>DESCRIPTION</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {datauvstabilzer.map((item, index) => (
                                                                <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                                    <td><p className="centeralignbelow">{item.grades}</p></td>
                                                                    <td><p className="centeralignbelow">{item.active}</p></td>
                                                                    <td><p className="centeralignbelow">{item.base}</p></td>
                                                                    <td> <p >{item.description}</p></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                            : selectedProduct1 === "Antioxidant Masterbatches" ? <>
                                                <div>
                                                    <p className="antiblockptag">ANTIOXIDANTS</p>
                                                    <div>
                                                        <table className="custom-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>GRADES</th>
                                                                    <th>ACTIVE CONTENT</th>
                                                                    <th>BASE POLYMER</th>
                                                                    <th>DESCRIPTION</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dataantioxidents.map((item, index) => (
                                                                    <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                                        <td><p className="centeralignbelow">{item.grades}</p></td>
                                                                        <td><p className="centeralignbelow">{item.active}</p></td>
                                                                        <td><p className="centeralignbelow">{item.base}</p></td>
                                                                        <td> <p >{item.description}</p></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                                : selectedProduct1 === "Cavitating Masterbatches" ? <>
                                                    <div>
                                                        <p className="antiblockptag">CAVITATING</p>
                                                        <div>
                                                            <table className="custom-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>GRADES</th>
                                                                        <th>ACTIVE CONTENT</th>
                                                                        <th>BASE POLYMER</th>
                                                                        <th>DESCRIPTION</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {datacaviting.map((item, index) => (
                                                                        <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                                            <td><p className="centeralignbelow">{item.grades}</p></td>
                                                                            <td><p className="centeralignbelow">{item.active}</p></td>
                                                                            <td><p className="centeralignbelow">{item.base}</p></td>
                                                                            {index === 0 && (
                                                                                <td rowSpan={datacaviting.length}>
                                                                                    <p>{item.description}</p> {/* Common description here */}
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </>
                                                : selectedProduct1 === "Processing Aid" ? <>
                                                <div>
                                                    <p className="antiblockptag">PROCESSING AID</p>
                                                    <div>
                                                        <table className="custom-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>GRADES</th>
                                                                    <th>ACTIVE CONTENT</th>
                                                                    <th>BASE POLYMER</th>
                                                                    <th>DESCRIPTION</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {datacaviting.map((item, index) => (
                                                                    <tr key={item.id} className={index % 2 === 0 ? "normal-row" : "gray-row"}>
                                                                        <td><p className="centeralignbelow">{item.grades}</p></td>
                                                                        <td><p className="centeralignbelow">{item.active}</p></td>
                                                                        <td><p className="centeralignbelow">{item.base}</p></td>
                                                                        {index === 0 && (
                                                                            <td rowSpan={datacaviting.length}>
                                                                                <p>{item.description}</p> {/* Common description here */}
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </>
                                                    : null
                }
            </div>
            <Footer />
        </>
    )
}

export default Additives;